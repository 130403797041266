






















import Vue from 'vue';
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
import { checkFileType } from '@/utils/index';
import AliOss from '@/utils/alioss';
export default Vue.extend({
  name: 'updateFile',
  props: {
    openFileDialog: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * @description: 上传文件
     * @param {*} params
     * @return {*}
     */
    async uploadFile(params: any) {
      console.log('updateFile.vue|55====>', params.file.name);
      const isType = checkFileType(params.file.name, 'xlsx', 'xls');
      if (!isType) {
        this.$message.error('上传文件格式不正确!');
      }
      const file = params.file;
      if (!file) {
        this.$emit('uploadFileCallback', null);
        return;
      }
      const url = await new AliOss().upload(
        file,
        'enterprise-quota/import-excel'
      );
      this.$emit('uploadFileCallback', url);
    },
    beforeUpload(file) {
      const isType = checkFileType(file.name, 'xlsx', 'xls');
      if (!isType) {
        this.$message.error('上传文件格式不正确!');
      }
      return isType;
    },
  },
});
